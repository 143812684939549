<template>
  <div>
    <b-card no-body>
      <b-overlay :show="toolbarLoading" variant="white" rounded="sm">
        <template #overlay>
          <div class="text-center">
            Generando PDF...
            <div class="d-flex align-items-center">
              <b-progress
                v-model="pdfGenerationPage"
                class="toolbar-progress-bar"
                animated
                :max="pdfGenerationTotalPages"
              />
              <FeatherIcon
                icon="XCircleIcon"
                size="25"
                class="text-danger cursor-pointer"
                @click="pdfGenerationCancelation = true"
              />
            </div>
          </div>
        </template>
        <TableToolBar
          :filters="filters"
          :totals="computedTotals"
          :resourceStore="'saleInvoice'"
          @printer-button-click="printList"
          @upload-button-click="exportList"
          @filter-button-click="filtersVisible = true"
          @on-clear-active-filters="handleClearListFilters"
          @totals-button-click="totalRowVisible = $event"
          @search="handleSearch"
        />
      </b-overlay>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        variant="white"
        rounded="sm"
      >
        <b-table
          responsive
          tbody-tr-class="tr-job"
          :no-local-sorting="true"
          :items="jobs"
          :fields="columns"
          @sort-changed="handleSortingChange({ column: $event.sortBy, type: $event.sortDesc ? 'desc' : 'asc' })"
          @row-clicked="rowClicked"
        >
          <template #thead-top>
            <b-collapse v-model="totalRowVisible" tag="b-tr">
              <template v-if="totalRowVisible">
                <b-th
                  v-for="total in computedTotals"
                  :key="total.label"
                  :colspan="total.colspan || 1"
                  :class="
                    'align-bottom font-weight-bold bg-action-blue ' +
                    (total.align ? `text-${total.align}` : '')
                  "
                >
                  <div v-show="total.label" class="text-dark-gray font-small">
                    {{ total.label }}
                  </div>
                  <div
                    v-show="total.value || total.value === 0"
                    class="text-primary text-nowrap"
                  >
                    {{ getTotalValue(total.value) + " " + (total.unit || "") }}
                  </div>
                </b-th>
              </template>
            </b-collapse>
          </template>
          <template #cell(show_details)="row">
            <b-link
              class="d-flex align-items-center justify-content-center"
              @click="row.toggleDetails"
            >
              <feather-icon
                v-if="row.detailsShowing"
                icon="ChevronUpIcon"
                size="20"
              />
              <feather-icon v-else icon="ChevronDownIcon" size="20" />
            </b-link>
          </template>
          <template #cell(status)="data">
            <StatusBadge :status="data.value.name" />
          </template>
          <template #cell(billed_percentage)="data">
            <div>{{ data.value | numberToLocalString }} %</div>
          </template>
          <template #cell(client)="data">
            <div>{{ data.value.name }}</div>
          </template>
          <template #cell(total_retention)="{ value, item }">
            <div :class="{'text-success': item.pending_collect === 0}" >
              {{ value | numberToLocalString }} €
            </div>
            <div
              v-show="item.retention_expiration"
              class="font-small text-light"
            >
              vence {{ item.retention_expiration | formatDate }}
            </div>
          </template>
          <template #row-details="row">
            <div class="py-1">
              <b-table
                hover
                :items="row.item.sale_invoices"
                :fields="columnsRetentionTable"
                @row-clicked="
                  $router.push({
                    name: 'viewSaleInvoice',
                    params: { id: $event.id },
                  })
                "
              >
                <template #cell(invoice_date)="data">
                  <div> {{ data.item.invoice_date |formatDate }} </div>
                  <div
                      v-show="data.item.invoice_due_date"
                      class="font-small text-light"
                  >
                    vence {{ data.item.invoice_due_date | formatDate }}
                  </div>
                  <div
                      v-show="data.item.invoice_collection"
                      class="font-small text-light"
                  >
                    cobro {{ data.item.invoice_collection | formatDate }}
                  </div>
                </template>
                <template #cell(client)="data">
                  <div>{{ data.item.client.name }}</div>
                </template>
                <template #cell(job)="data">
                  <div>{{ data.item.job.name }}</div>
                </template>
                <template #cell(total_retention)="{ value, item }">
                  <div>{{ value | numberToLocalString }} €</div>
                  <div
                    v-show="item.retention_expiration"
                    class="font-small text-light"
                  >
                    vence {{ item.retention_expiration | formatDate }}
                  </div>
                </template>
                <template #cell(invoice_due_date)="{ value, item }">
                  <span
                      v-show="item.status.name === 'Pendiente de cobro' || item.status.name === 'Cobro parcial'"
                      :class="(isDueDateExpired(value) ? 'text-danger' : 'text-primary') +' text-uppercase'"
                  >
                    {{ getDueDateDaysText(value) }}
                  </span>
                </template>
                <template #cell(status)="data">
                  <StatusBadge
                            :status="data.item.status.name"
                            :text="data.item.status.name === 'Cobro parcial' ?
                  `Cobro parcial ${data.item.percent !== 0 && data.item.percent !== 100 ? `${data.item.percent} % ` : '' }`
                : null"
                  />
                  <StatusBadge
                      v-if="data.item.received_payment_document && data.item.received_payment_document === 1"
                      class="mt-1"
                      :status="'Rec. Doc. Cobro'"
                      :text="'Rec. Doc. Cobro'"
                      :variant="'light-success'"
                  />
                </template>
                <template #cell(actions)="data">
                  <b-link class="d-inline-block text-indigo">
                    <feather-icon
                      icon="DownloadIcon"
                      size="18"
                      @click.stop="handlePrintLinkClick(data.item.id)"
                    />
                  </b-link>
                  <b-link
                    :to="{
                      name: 'viewSaleInvoice',
                      params: { id: data.item.id },
                    }"
                    class="px-1 d-inline-block text-indigo"
                  >
                    <feather-icon
                      v-b-tooltip.hover
                      title="Eliminar"
                      icon="EyeIcon"
                      size="18"
                    />
                  </b-link>
                  <b-link
                    v-access="{
                      resource:
                        $data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,
                      resourceAction:
                        $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,
                    }"
                    class="d-inline-block text-danger"
                    @click="handleDeleteIconClick(data.item)"
                  >
                    <feather-icon
                      v-b-tooltip.hover
                      title="Eliminar"
                      icon="TrashIcon"
                      size="18"
                    />
                  </b-link>
                </template>
              </b-table>
            </div>
          </template>
        </b-table>
      </b-overlay>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between mb-1 px-2"
      >
        <div
          class="d-flex align-items-center text-light py-2"
          style="flex-grow: 0"
        >
          <span
            v-if="$store.getters['app/currentBreakPoint'] !== 'xs'"
            class="text-nowrap"
          >
            Mostrando:
          </span>
          <b-form-select
            v-model="pageLength"
            :options="pages"
            class="ml-50 mr-1"
            @input="handlePageLengthChange"
          />
          <span class="text-nowrap"> de: {{ totalItems }} entradas</span>
        </div>
        <div
          :class="
            $store.getters['app/currentBreakPoint'] === 'sm' ||
            $store.getters['app/currentBreakPoint'] === 'xs'
              ? 'justify-content-center'
              : 'justify-content-end'
          "
          class="footer-item d-flex"
          style="flex-grow: 1"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="handlePageChange"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-card>
    <SaleInvoicesRetentionListFilters
      ref="sale-invoices-retention-list-filters"
      v-model="filtersVisible"
      :baseFilters="filters"
      @filters-submit="$store.commit('saleInvoice/setRetentionFilters', $event)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import SaleInvoicesRetentionListFilters from "@/components/sale-invoices/filters/SaleInvoicesRetentionListFilters.vue";
import TableToolBar from "@/components/ui/table/TableToolBar.vue";
import saleInvoicesApi from "@/api/sale-invoices-api";
import DownloadService from "@/shared/services/download-service";
import StatusBadge from "@/components/status/badge/StatusBadge.vue";
import DateTimeService from "@/shared/services/date-time-service";

export default {
  components: {
    StatusBadge,
    TableToolBar,
    SaleInvoicesRetentionListFilters
  },
  data() {
    return {
      loading: false,
      filtersVisible: false,
      totalRowVisible: false,
      totalItems: 0,
      currentPage: 1,
      pageLength: 10,
      pages: ["5", "10", "25", "50"],
      pdfGenerationPage: 1,
      pdfGenerationTotalPages: Infinity,
      pdfGenerationCancelation: false,
      localLoading: false,
      toolbarLoading: false,
      columns: [
        {
          label: "Nombre",
          key: "name",
          // thStyle: { "min-width": "150px" },
          sortable: true,
        },
        {
          label: 'ESTADO',
          key: 'status',
          sortable: false,
        },
        {
          label: '% FACTURADO',
          key: 'billed_percentage',
          // thClass: 'text-right',
          // tdClass: 'text-right',
          thStyle: { "min-width": "140px" },
          sortable: false,
        },
        {
          label: "Cliente",
          key: "client",
          sortable: true,
        },
        {
          label: "Retencion",
          key: "total_retention",
        },
        {
          label: "",
          key: "show_details",
        },
      ],
      columnsRetentionTable: [
        {
          label: "NÚMERO",
          key: "code",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { "min-width": "100px" },
        },
        {
          label: 'FECHA',
          key: 'invoice_date',
          sortable: true,
        },
        {
          label: "VENCIMIENTO",
          key: "invoice_due_date",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
        {
          label: "JOB",
          key: "job",
          sortable: true,
        },
        {
          label: "CLIENTE",
          key: "client",
          sortable: true,
        },
        {
          label: "RETENCIÓN",
          key: "total_retention",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
        {
          label: "ESTADO",
          key: "status",
          sortable: true,
        },
        {
          label: "ACCIONES",
          key: "actions",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { "min-width": "210px" },
        },
      ],
      jobs: [],
      totals: [],
      totalsConfiguration: [
        { colspan: 4 },
        { label: "TOTAL", key: "total_retention", unit: "€" },
        { colspan: 1 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      filters: "saleInvoice/getRetentionFilters",
    }),
    search() {
      return this.$store.getters[`saleInvoice/getSearch`];
    },
    computedTotals() {
      if (!this.totals || !this.totalsConfiguration) {
        return [];
      }

      return this.totalsConfiguration.reduce((accomulator, item) => {
        accomulator.push({
          label: item.label,
          value: this.totals[item.key],
          colspan: item.colspan,
          unit: item.unit,
          align: item.align || "right",
        });
        return accomulator;
      }, []);
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.currentPage = 1;
        this.loadData();
      },
    },
  },
  methods: {
    getDueDateDaysText(dueDate) {
      if (!dueDate) {
        return ''
      }

      const days = DateTimeService.getDifferenceInDays(new Date(), new Date(dueDate))
      return this.isDueDateExpired(dueDate) ? `Vencida ${days} dias` : `Vence en ${days} dias`
    },
    isDueDateExpired(dueDate) {
      if (!dueDate) {
        return false
      }

      return new Date() > new Date(dueDate)
    },
    handleClearListFilters() {
      this.$store.commit("saleInvoice/setSearch", null);
      this.$store.commit("saleInvoice/setRetentionFilters", {});
      this.$refs["sale-invoices-retention-list-filters"].clearFilters();
    },
    rowClicked(item, index, event) {
      // Verifica si la propiedad '_showDetails' existe en el objeto 'item'
      if (Object.prototype.hasOwnProperty.call(item, "_showDetails")) {
        // Alterna el valor de '_showDetails'
        this.$set(item, "_showDetails", !item._showDetails);
      } else {
        // Si '_showDetails' no existe, la crea y la establece en 'true'
        this.$set(item, "_showDetails", true);
      }
    },
    async exportList() {
      this.localLoading = true;
      try {
        const response = await Vue.prototype.$http.post(
          `/retentions/export`,
          {
            orderBy: this.orderBy,
            search: this.searchTerm,
            ...this.filters,
          },
          { responseType: "blob" }
        );
        const filename = DownloadService.getFilenameFromResponse(response);
        DownloadService.resolveAndDownloadBlob(
          response?.data || null,
          filename
        );
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "Error en la descarga del listado. Por favor inténtelo de nuevo mas tarde."
        );
      }
      this.localLoading = false;
    },
    async printList() {
      this.toolbarLoading = true;
      let response = null;
      try {
        while (
          this.pdfGenerationPage <= this.pdfGenerationTotalPages &&
          !this.pdfGenerationCancelation
        ) {
          // eslint-disable-next-line no-await-in-loop
          response = await Vue.prototype.$http.post(
            `/retentions/print`,
            {
              page: this.pdfGenerationPage,
              orderBy: this.orderBy,
              search: this.search,
              ...this.filters,
            },
            {
              responseType:
                this.pdfGenerationPage === this.pdfGenerationTotalPages
                  ? "blob"
                  : null,
            }
          );
          this.pdfGenerationPage += 1;
          this.pdfGenerationTotalPages = response?.data?.total + 1 || 0;
        }

        if (response?.data && !this.pdfGenerationCancelation) {
          const filename = DownloadService.getFilenameFromResponse(response);
          DownloadService.resolveAndDownloadBlob(response.data, filename);
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "Error en la descarga del listado. Por favor inténtelo de nuevo mas tarde."
        );
      }
      this.pdfGenerationPage = 1;
      this.pdfGenerationTotalPages = Infinity;
      this.pdfGenerationCancelation = false;
      this.toolbarLoading = false;
    },
    async handlePrintLinkClick(saleInvoiceId) {
      this.loading = true;
      try {
        await saleInvoicesApi.printSaleInvoice(saleInvoiceId);
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteIconClick(saleInvoice) {
      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: `Se borrará el registro Nº${saleInvoice.code}.`,
      });

      if (!response.isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await saleInvoicesApi.delete(saleInvoice.id);
        await this.loadData();
      } finally {
        this.loading = false;
      }
    },
    handleSortingChange(orderBy) {
      this.orderBy = orderBy;
      this.loadData();
    },
    handleSearch() {
      this.currentPage = 1;
      this.loadData();
    },
    handlePageLengthChange() {
      this.currentPage = 1;
      this.loadData();
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadData();
    },
    async loadData() {
      this.loading = true;

      const filters = {
        search: this.search,
        page: this.currentPage,
        per_page: this.pageLength,
        orderBy: this.orderBy,
        ...this.filters,
      };

      try {
        const response = await saleInvoicesApi.jobList(filters);
        this.jobs = response.data;
        this.totalItems = response.meta?.total[1];
        this.totals = response.totals;
        // console.log(response);
      } catch (error) {
        this.$toast.error(
          "Ocurrió un error al obtener los datos. Por favor inténtelo de nuevo mas tarde."
        );
      }

      this.loading = false;
    },
    getTotalValue(total) {
      if (typeof total === 'number') {
        return this.$options.filters.numberToLocalString(total)
      }

      return total
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss">
.tr-job {
  height: 70px;
  cursor: pointer;
}
.footer-item {
  flex-grow: 1;
}
.toolbar-progress-bar {
  min-width: 300px;
  height: 20px;
}
</style>
