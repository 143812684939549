var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.toolbarLoading,"variant":"white","rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" Generando PDF... "),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-progress',{staticClass:"toolbar-progress-bar",attrs:{"animated":"","max":_vm.pdfGenerationTotalPages},model:{value:(_vm.pdfGenerationPage),callback:function ($$v) {_vm.pdfGenerationPage=$$v},expression:"pdfGenerationPage"}}),_c('FeatherIcon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"XCircleIcon","size":"25"},on:{"click":function($event){_vm.pdfGenerationCancelation = true}}})],1)])]},proxy:true}])},[_c('TableToolBar',{attrs:{"filters":_vm.filters,"totals":_vm.computedTotals,"resourceStore":'saleInvoice'},on:{"printer-button-click":_vm.printList,"upload-button-click":_vm.exportList,"filter-button-click":function($event){_vm.filtersVisible = true},"on-clear-active-filters":_vm.handleClearListFilters,"totals-button-click":function($event){_vm.totalRowVisible = $event},"search":_vm.handleSearch}})],1),_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","variant":"white","rounded":"sm"}},[_c('b-table',{attrs:{"responsive":"","tbody-tr-class":"tr-job","no-local-sorting":true,"items":_vm.jobs,"fields":_vm.columns},on:{"sort-changed":function($event){return _vm.handleSortingChange({ column: $event.sortBy, type: $event.sortDesc ? 'desc' : 'asc' })},"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [_c('b-collapse',{attrs:{"tag":"b-tr"},model:{value:(_vm.totalRowVisible),callback:function ($$v) {_vm.totalRowVisible=$$v},expression:"totalRowVisible"}},[(_vm.totalRowVisible)?_vm._l((_vm.computedTotals),function(total){return _c('b-th',{key:total.label,class:'align-bottom font-weight-bold bg-action-blue ' +
                  (total.align ? ("text-" + (total.align)) : ''),attrs:{"colspan":total.colspan || 1}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(total.label),expression:"total.label"}],staticClass:"text-dark-gray font-small"},[_vm._v(" "+_vm._s(total.label)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(total.value || total.value === 0),expression:"total.value || total.value === 0"}],staticClass:"text-primary text-nowrap"},[_vm._v(" "+_vm._s(_vm.getTotalValue(total.value) + " " + (total.unit || ""))+" ")])])}):_vm._e()],2)]},proxy:true},{key:"cell(show_details)",fn:function(row){return [_c('b-link',{staticClass:"d-flex align-items-center justify-content-center",on:{"click":row.toggleDetails}},[(row.detailsShowing)?_c('feather-icon',{attrs:{"icon":"ChevronUpIcon","size":"20"}}):_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}},{key:"cell(status)",fn:function(data){return [_c('StatusBadge',{attrs:{"status":data.value.name}})]}},{key:"cell(billed_percentage)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("numberToLocalString")(data.value))+" %")])]}},{key:"cell(client)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.value.name))])]}},{key:"cell(total_retention)",fn:function(ref){
                  var value = ref.value;
                  var item = ref.item;
return [_c('div',{class:{'text-success': item.pending_collect === 0}},[_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(value))+" € ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.retention_expiration),expression:"item.retention_expiration"}],staticClass:"font-small text-light"},[_vm._v(" vence "+_vm._s(_vm._f("formatDate")(item.retention_expiration))+" ")])]}},{key:"row-details",fn:function(row){return [_c('div',{staticClass:"py-1"},[_c('b-table',{attrs:{"hover":"","items":row.item.sale_invoices,"fields":_vm.columnsRetentionTable},on:{"row-clicked":function($event){return _vm.$router.push({
                  name: 'viewSaleInvoice',
                  params: { id: $event.id },
                })}},scopedSlots:_vm._u([{key:"cell(invoice_date)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.invoice_date))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(data.item.invoice_due_date),expression:"data.item.invoice_due_date"}],staticClass:"font-small text-light"},[_vm._v(" vence "+_vm._s(_vm._f("formatDate")(data.item.invoice_due_date))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(data.item.invoice_collection),expression:"data.item.invoice_collection"}],staticClass:"font-small text-light"},[_vm._v(" cobro "+_vm._s(_vm._f("formatDate")(data.item.invoice_collection))+" ")])]}},{key:"cell(client)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.item.client.name))])]}},{key:"cell(job)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.item.job.name))])]}},{key:"cell(total_retention)",fn:function(ref){
                var value = ref.value;
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("numberToLocalString")(value))+" €")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.retention_expiration),expression:"item.retention_expiration"}],staticClass:"font-small text-light"},[_vm._v(" vence "+_vm._s(_vm._f("formatDate")(item.retention_expiration))+" ")])]}},{key:"cell(invoice_due_date)",fn:function(ref){
                var value = ref.value;
                var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.status.name === 'Pendiente de cobro' || item.status.name === 'Cobro parcial'),expression:"item.status.name === 'Pendiente de cobro' || item.status.name === 'Cobro parcial'"}],class:(_vm.isDueDateExpired(value) ? 'text-danger' : 'text-primary') +' text-uppercase'},[_vm._v(" "+_vm._s(_vm.getDueDateDaysText(value))+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('StatusBadge',{attrs:{"status":data.item.status.name,"text":data.item.status.name === 'Cobro parcial' ?
                ("Cobro parcial " + (data.item.percent !== 0 && data.item.percent !== 100 ? ((data.item.percent) + " % ") : ''))
              : null}}),(data.item.received_payment_document && data.item.received_payment_document === 1)?_c('StatusBadge',{staticClass:"mt-1",attrs:{"status":'Rec. Doc. Cobro',"text":'Rec. Doc. Cobro',"variant":'light-success'}}):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('b-link',{staticClass:"d-inline-block text-indigo"},[_c('feather-icon',{attrs:{"icon":"DownloadIcon","size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.handlePrintLinkClick(data.item.id)}}})],1),_c('b-link',{staticClass:"px-1 d-inline-block text-indigo",attrs:{"to":{
                    name: 'viewSaleInvoice',
                    params: { id: data.item.id },
                  }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"EyeIcon","size":"18"}})],1),_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
                    resource:
                      _vm.$data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,
                    resourceAction:
                      _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,
                  }),expression:"{\n                    resource:\n                      $data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,\n                    resourceAction:\n                      $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,\n                  }"}],staticClass:"d-inline-block text-danger",on:{"click":function($event){return _vm.handleDeleteIconClick(data.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1)]}}],null,true)})],1)]}}])})],1),_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-between mb-1 px-2"},[_c('div',{staticClass:"d-flex align-items-center text-light py-2",staticStyle:{"flex-grow":"0"}},[(_vm.$store.getters['app/currentBreakPoint'] !== 'xs')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" Mostrando: ")]):_vm._e(),_c('b-form-select',{staticClass:"ml-50 mr-1",attrs:{"options":_vm.pages},on:{"input":_vm.handlePageLengthChange},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de: "+_vm._s(_vm.totalItems)+" entradas")])],1),_c('div',{staticClass:"footer-item d-flex",class:_vm.$store.getters['app/currentBreakPoint'] === 'sm' ||
          _vm.$store.getters['app/currentBreakPoint'] === 'xs'
            ? 'justify-content-center'
            : 'justify-content-end',staticStyle:{"flex-grow":"1"}},[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.totalItems,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.handlePageChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1),_c('SaleInvoicesRetentionListFilters',{ref:"sale-invoices-retention-list-filters",attrs:{"baseFilters":_vm.filters},on:{"filters-submit":function($event){return _vm.$store.commit('saleInvoice/setRetentionFilters', $event)}},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }